import React from 'react'

import FooterMenu from './footerMenu'
import ContactMenu from './contactMenu'

const Footer = () => (
    <footer>
        <div className='container'>
             <div className='footer-menus'>
                <FooterMenu/>
                <ContactMenu/>
             </div>
        </div>
    </footer>
)

export default Footer
