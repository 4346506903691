import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { IntlProvider, addLocaleData } from 'react-intl'
import Helmet from 'react-helmet'

// Locale data
import enData from 'react-intl/locale-data/en'
import frData from 'react-intl/locale-data/fr'

// Messages
import en from '../../i18n/en.json'
import fr from '../../i18n/fr.json'

// Components
import Header from './Header'
import Footer from './Footer'
import favicon from '../../assets/images/favicon.ico'

const messages = { en, fr }

addLocaleData([...enData, ...frData])

const Layout = ({ locale = 'en', children }) => {
    const description = locale === 'fr'
        ? `Passionné de l’industrie web? Nous sommes constamment à la recherche de nouveau talent pour rejoindre notre équipe. Découvrez nos offres d’emplois.`
        : `Passionate about the web industry? We are always looking for experts to join our team. Discover our latest job offers.`
    return (
        <IntlProvider locale={locale} messages={messages[locale]}>
            <Fragment>
                <Helmet>
                    <title>Evolving Web</title>
                    <meta property="og:site_name" content="Evolving Web" />
                    <link rel="shortcut icon" href={favicon} type="image/vnd.microsoft.icon" />
                    <meta name="description" content={description} />
                    <meta name="og:description" content={description} />
                    <meta name="twitter:description" content={description} />
                </Helmet>
                <Header />
                <main className="main-content">{children}</main>
                <Footer/>
            </Fragment>
        </IntlProvider>
    )
}

Layout.propTypes = {
  locale: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node)
  ]).isRequired
}

export default Layout
