import $ from 'jquery'

export const stickyNav = () => {
    var prevScrollTop = 0;
    var trgt = $('header');
    $(window).scroll(function(event) {
        var headerHeight = $('header').outerHeight();
        var scrollTop = $(this).scrollTop();
        if (scrollTop < 0) {
            scrollTop = 0;
        }
        if (scrollTop > $("body").height() - $(window).height()) {
            scrollTop = $("body").height() - $(window).height();
        }
        if (scrollTop >= prevScrollTop && scrollTop >= headerHeight) {
            if (!$(trgt).hasClass("hide")) {
                $(trgt).addClass("hide");
                $('body').removeClass('js-menu-opened');
            }
        } else {
            if ($(trgt).hasClass("hide")) {
                $(trgt).removeClass("hide");
            }
        }
        prevScrollTop = scrollTop;
    });
}
