import React, { Fragment } from 'react'
import { Link } from 'gatsby'
import { injectIntl, intlShape } from 'react-intl'
import locales from '../../../constants/locales'

const LanguageSwitcher = ({ intl: { locale } }) => (
    <Fragment>
        {Object.keys(locales).map(key => {
            if (key === locale) return

            return (
                <Link
                    className='nav-link'
                    key={locales[key].locale}
                    to={`/${locales[key].path}`}
                >
                    {locales[key].locale}
                </Link>
            )
        })}
    </Fragment>
)

LanguageSwitcher.propTypes = {
    intl: intlShape.isRequired
}

// LanguageSwitcher.propTypes = { intl: intlShape.isRequired }

export default injectIntl(LanguageSwitcher)

const getCurrentPath = () => {
    const pathFragments = window.location.pathname.split('/').shift()
    return pathFragments.join('/')
}
