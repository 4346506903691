module.exports = {
    _home: {
        en: 'home',
        fr: 'accueil'
    },
    _open_positions: {
        en: 'open-positions',
        fr: 'postes-ouverts'
    },
    _apply_now: {
        en: 'https://evolving-web.workable.com',
        fr: 'https://evolving-web.workable.com'
    }
}