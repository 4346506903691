import React, { Fragment } from 'react'
import { graphql, StaticQuery } from 'gatsby'
import $ from 'jquery'

import { FormattedMessage } from 'react-intl'
import LanguageSwitcher from './languageSwitcher'
import LocalizedLink from '../../LocalizedLink'

const Navigation = () => (
    <StaticQuery query={query}
        render={(data) => {
            const { siteUrl } = data.site.siteMetadata
            return (
                <Fragment>
                    <button className="navbar-toggler" type="button" data-target="#navbarNav"
                            onClick={onNavbarToggleClicked}
                            aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="navbar-collapse" id="navbarNav">
                        <ul className="navbar-nav ml-auto">
                            <li className="nav-item">
                                <LocalizedLink to='_open_positions' className='nav-link'>
                                    <FormattedMessage id='main-menu--link--open-positions'/>
                                </LocalizedLink>
                            </li>
                            <li className="nav-item">
                                <LocalizedLink to='_apply_now' className='nav-link'>
                                    <FormattedMessage id='main-menu--link--apply-now'/>
                                </LocalizedLink>
                            </li>
                            <li className="nav-item">
                                <a href={siteUrl}
                                   target='_blank'
                                   className='nav-link'>evolvingweb.ca <i className="fa fa-external-link" aria-hidden="true"></i></a>
                            </li>
                            <li className="nav-item">
                                <LanguageSwitcher />
                            </li>
                        </ul>
                    </div>
                </Fragment>
            )
        }}
    />
)

export default Navigation

const onNavbarToggleClicked = (e) => {
    e.preventDefault()
    $('body').toggleClass('js-menu-opened');
}

const query = graphql`
    query {
      site {
        siteMetadata {
          title
          siteUrl
        }
      }
    }
`