module.exports = {
  en: {
    path: 'en',
    locale: 'EN',
    default: true
  },
  fr: {
    path: 'fr',
    locale: 'FR'
  }
}
