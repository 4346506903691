import React from 'react'
import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import { injectIntl, intlShape } from 'react-intl'

import ROUTES from '../../constants/routing'

const LocalizedLink = ({to, children, intl: { locale },  ...props}) => {
    if (typeof ROUTES[to] !== 'undefined') {
        to = ROUTES[to][locale]
    }

    let pattern = /^(?:http(s)?:\/\/)/
    return pattern.test(to)
        ? <a className={props.className} href={`${to}`} target={'_blank'}>{children}</a>
        : <Link {...props} to={`/${locale}/${to}`}>{children}</Link>
}

LocalizedLink.propTypes = {
  to: PropTypes.string.isRequired,
  intl: intlShape.isRequired
}

export default injectIntl(LocalizedLink)
