import React from 'react'
import { Link, StaticQuery, graphql } from 'gatsby'
import { injectIntl, intlShape } from 'react-intl'

import Logo from './images/logo.svg'
import Navigation from './navigation'
import { stickyNav } from './navigation.helper'

class Header extends React.PureComponent {
    componentDidMount() {
        stickyNav()
    }

    render() {
        return (
            <StaticQuery
                query={query}
                render={data => (
                    <header>
                        <nav className="navbar navbar-expand-lg navbar-light container">
                            <Link to='/en' className='navbar-brand'>
                                <img src={Logo} alt={data.site.siteMetadata.title} className='logo'/>
                            </Link>
                            <Navigation/>
                        </nav>
                    </header>
                )}
            />
        )
    }
}

Header.propTypes = { intl: intlShape.isRequired }

export default injectIntl(Header)

const query = graphql`
    query HeaderQuery {
        site {
            siteMetadata {
                title
            }
        }
    }
`
