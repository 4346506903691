import React from 'react'
import LocalizedLink from "../../LocalizedLink"
import {FormattedMessage} from "react-intl";

const FooterMenu = () => (
    <nav className='footer-menu'>
        <ul>
            <li>
                <LocalizedLink to='_open_positions'>
                    <FormattedMessage id='footer-menu--link--open-positions'/>
                </LocalizedLink>
            </li>
            <li>
                <LocalizedLink to='_apply_now'>
                    <FormattedMessage id='footer-menu--link--apply'/>
                </LocalizedLink>
            </li>
            <li>
                <a href='https://evolvingweb.ca/'
                   target='_blank'>evolvingweb.ca <i className="fa fa-external-link" aria-hidden="true"></i></a>
            </li>
        </ul>
    </nav>
)

export default FooterMenu