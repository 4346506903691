import React from 'react'
import LocalizedLink from '../../LocalizedLink'
import {FormattedMessage} from 'react-intl'
import { StaticQuery, graphql } from 'gatsby'

const ContactMenu = () => (
    <StaticQuery query={query}
        render={data => {
            return (
                <nav className='contact-menu'>
                    <ul>
                        <li>
                            <LocalizedLink to='https://evolvingweb.ca/contact'>
                                <FormattedMessage id='contact-menu--link--contact'/>
                            </LocalizedLink>
                        </li>
                        <li>
                            <a href='https://www.facebook.com/Evolving-Web-321255477898272'
                               className='link-social--facebook'
                               target='_blank'>Facebook</a>
                        </li>
                        <li>
                            <a href='https://instagram.com/evolving_web'
                               className='link-social--instagram'
                               target='_blank'>Instagram</a>
                        </li>
                        <li>
                            <a href='https://twitter.com/evolvingweb'
                               className='link-social--twitter'
                               target='_blank'>Twitter</a>
                        </li>
                    </ul>
                </nav>
            )
        }}
    />

)

export default ContactMenu

const query = graphql`
    query {
        site {
            siteMetadata {
                title
            }
        }
    }
    `